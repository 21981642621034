import React, { useState, useEffect } from 'react';
import './Header.css';
import VagmineLogo from '../images/logo final vagmine.png';
import { Link } from 'react-router-dom';
import AddIcCallRoundedIcon from '@mui/icons-material/AddIcCallRounded';

function Header() {
  const [prevScrollPos, setPrevScrollPos] = useState(window.pageYOffset);
  const [visible, setVisible] = useState(true);

  const handleScroll = () => {
    const currentScrollPos = window.pageYOffset;
    const visible = prevScrollPos ;

    setPrevScrollPos(currentScrollPos);
    setVisible(visible);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [prevScrollPos, visible]);

  return (
    <header className={`header ${visible ? 'header-visible' : 'header-hidden'}`}>
      <div className="container">
        <div className="logo">
          <Link to='/'><img src={VagmineLogo} alt="Veer Chemicals Logo" /></Link>
        </div>
        <nav className="nav">
          <ul>
            <li><Link to="/">Home</Link></li>
            <li><Link to="/AboutUs">About Us</Link></li>
            <li className="dropdown">
              <button>Products+</button>
              <div className="dropdown-menu">
                <Link to="/Pharma">Pharmaceuticles +</Link>
                
                <Link to="/Product2">Dyes & Pigments</Link>
              </div>
            </li>
            <li><Link to="/ContactUs">Contact Us</Link></li>
          </ul>
        </nav>
        <div className="iconDiv">
          <AddIcCallRoundedIcon className="icon" />
          <p className="iconText"> +91 98258 81463</p>
        </div>
      </div>
    </header>
    
  );
}

export default Header;
